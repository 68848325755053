.container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    align-items: center;
    text-align: center;

    height: 80vh;
    margin: auto 1rem;
}

.text {
    margin-bottom: 1rem;
}

.contact {
    background-color: #B10B30;
    color: white;

    padding: 1rem 2rem;
}

.contact:hover {
    background-color: #A10A2A;
    padding: 1.25rem 2.5rem;
}

.links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    margin: 1rem 0;
    width: 10rem;

    font-size: 32px;
}