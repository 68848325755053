i {
    padding-right: 0.3em;
    color: #B10B30;
}

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    align-items: center;
    text-align: center;
}

@media (min-width: 720px) {
    .skills {
        width: 80%;
    
        display: flex;
    
        flex-direction: row;
        flex-wrap: wrap;
    
        justify-content: center;
    }

    .container {
        height: 75vh;
        margin: auto 1rem;
    }
}

@media (min-width: 960px) {
    .skills {
        width: 50%;
    
        display: flex;
    
        flex-direction: row;
        flex-wrap: wrap;
    
        justify-content: center;
    }

    .container {
        height: 75vh;
        margin: auto 1rem;
    }
}

@media (min-width: 1280px) {
    .skills {
        width: 30%;
    
        display: flex;
    
        flex-direction: row;
        flex-wrap: wrap;
    
        justify-content: center;
    }

    .container {
        height: 75vh;
        margin: auto 1rem;
    }
}

@media (max-width: 720px) {
    .skills {
        width: 90%;
    
        display: flex;
    
        flex-direction: row;
        flex-wrap: wrap;
    
        justify-content: center;
    }

    .container {
        margin: 3rem 0rem;
    }
}

.skill {
    padding: 0.75rem 1rem;
    margin: 0.5rem;

    background-color: #F0F0F0;
}

.skill:hover {
    color: #B10B30;
}
