.navigation {
    padding: 1rem;
}

.title {
    font-weight: 600;
}

.link {
    color: #000;
    padding: 0.5rem 1rem;
}

.link:hover {
    color: #B10B30;
}

@media (min-width: 720px) {
    .container {
        width: 700px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .links {
        display: flex;
        justify-content: flex-end;
    }
}

@media (min-width: 960px) {
    .container {
        width: 900px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .links {
        display: flex;
        justify-content: flex-end;
    }
}

@media (min-width: 1280px) {
    .container {
        width: 1100px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .links {
        display: flex;
        justify-content: flex-end;
    }
}

@media (max-width: 720px) {
    .title {
        display: none
    }

    .links {
        display: flex;
        justify-content: space-around;
    }
}