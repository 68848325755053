@font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans-Light.woff2') format('woff2');
         font-weight: 300;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans-Regular.woff2') format('woff2');
         font-weight: 400;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans-Medium.woff2') format('woff2');
         font-weight: 500;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans-Semibold.woff2') format('woff2');
         font-weight: 600;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'General Sans';
    src: url('../fonts/GeneralSans-Bold.woff2') format('woff2');
         font-weight: 700;
         font-style: normal;
  }
  