@import url('./fonts.css');

* {
    margin: 0;
    padding: 0;

    font-family: 'General Sans', sans-serif;
    font-weight: 500;

    transition: all 0.2s ease-in-out;
}

a {
    text-decoration: none;
    color: inherit;
}

span {
    color: #B10B30;
    font-weight: inherit;
}

h1 {
    font-size: 48px;
    font-weight: 600;
}

.title {
    margin-bottom: 1rem;
}

::selection {
    background: #B10B30;
    color: white; 
}

::-moz-selection {
    background: #B10B30;
    color: white; 
}

::-webkit-scrollbar {
	width: 0.5rem;
}

::-webkit-scrollbar-track {
	background-color: #F0F0F0;
}

::-webkit-scrollbar-thumb {
	background-color: #B10B30;
}